.menu-outer-buttons {
  position: absolute; }
  .menu-outer-buttons .menu-button {
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: transparent;
    width: 50px;
    height: 50px;
    transition: all .150s ease-in-out;
    position: absolute;
    opacity: 0;
    top: 0; }
    .menu-outer-buttons .menu-button.btn-flag.flag-en {
      background-image: url(../img/icn/en.jpg) !important; }
    .menu-outer-buttons .menu-button.btn-flag.flag-fr {
      background-image: url(../img/icn/fr.png) !important; }
    .menu-outer-buttons .menu-button.btn-menu {
      position: relative;
      z-index: 1;
      opacity: 1;
      background-image: url(../img/icn/menu.svg) !important;
      border-radius: 50% !important;
      color: #FFFFFF !important;
      border: none !important;
      background-color: rgba(255, 255, 225, 0.5) !important;
      box-shadow: none !important;
      padding: 20px !important;
      background-size: 60% auto !important; }
    .menu-outer-buttons .menu-button.btn-flag {
      position: relative;
      z-index: 1;
      opacity: 1;
      margin-top: 1em;
      border-radius: 50% !important;
      color: #000 !important;
      border: none !important;
      background-color: #FFF !important;
      box-shadow: none !important;
      padding: 20px !important; }
      .menu-outer-buttons .menu-button.btn-flag.flag-en {
        border: 1px solid white !important; }
    .menu-outer-buttons .menu-button.btn-home.open {
      top: -60px;
      opacity: 1; }
    .menu-outer-buttons .menu-button.btn-refresh.open {
      top: -30px;
      right: -60px;
      opacity: 1; }
    .menu-outer-buttons .menu-button.btn-logout.open {
      top: 30px;
      right: -57px;
      opacity: 1; }
    .menu-outer-buttons .menu-button.btn-home {
      background-image: url(../img/icn/home.svg);
      border-radius: 50%;
      color: #FFFFFF;
      border: none;
      background-color: rgba(255, 255, 225, 0.5);
      box-shadow: none;
      padding: 20px;
      background-size: 60% auto;
      top: 0px;
      right: 0px;
      opacity: 0; }
    .menu-outer-buttons .menu-button.btn-backk {
      background-image: url(../img/icn/left-arrow.svg);
      border-radius: 50%;
      color: #FFFFFF;
      border: none;
      background-color: rgba(255, 255, 225, 0.5);
      box-shadow: none;
      padding: 20px;
      background-size: 60% auto;
      opacity: 1;
      top: -65px; }
      .menu-outer-buttons .menu-button.btn-backk.open {
        top: -120px; }
      .menu-outer-buttons .menu-button.btn-backk.closed {
        top: -65px; }
    .menu-outer-buttons .menu-button.btn-refresh {
      background-image: url(../img/icn/update-arrows.svg);
      border-radius: 50%;
      color: #FFFFFF;
      border: none;
      background-color: rgba(255, 255, 225, 0.5);
      box-shadow: none;
      padding: 20px;
      background-size: 60% auto;
      top: 0px;
      right: 0px;
      opacity: 0; }
    .menu-outer-buttons .menu-button.btn-logout {
      background-image: url(../img/icn/logout.svg);
      border-radius: 50%;
      color: #FFFFFF;
      border: none;
      background-color: rgba(255, 255, 225, 0.5);
      box-shadow: none;
      padding: 20px;
      background-size: 60% auto;
      top: 0px;
      right: 0px;
      opacity: 0; }

.section-list {
  white-space: nowrap; }
  .section-list .section {
    padding: 0;
    margin: 0;
    display: flex;
    position: relative; }
    .section-list .section .section-item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      position: relative; }
    .section-list .section .image {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: cover;
      background-position: center;
      flex: 1; }
    .section-list .section .title {
      max-width: 90%;
      position: absolute;
      bottom: 0;
      padding: 0.4em 0.8em;
      padding-bottom: 0;
      text-transform: uppercase;
      white-space: normal;
      font-weight: bold; }
  .section-list .section-item.left .title {
    left: 0; }
  .section-list .section-item.right .title {
    right: 0; }
  .section-list .section-item.center {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column; }

/* Horizontal mode */
.main-menu.top, .main-menu.bottom {
  height: 125px; }
  .main-menu.top .scroll, .main-menu.bottom .scroll {
    height: 100%; }
  .main-menu.top .section-list, .main-menu.bottom .section-list {
    height: 100%;
    padding: 0.4em 0; }
    .main-menu.top .section-list .section, .main-menu.bottom .section-list .section {
      display: inline-block;
      width: 150px;
      height: 100%;
      margin: 0 0.2em; }
      .main-menu.top .section-list .section .section-item, .main-menu.bottom .section-list .section .section-item {
        height: 100%; }
        .main-menu.top .section-list .section .section-item .image, .main-menu.bottom .section-list .section .section-item .image {
          height: 100%; }

.main-menu.top .menu-outer-buttons, .menu-position-top .menu-outer-buttons {
  top: 100%;
  left: auto;
  bottom: auto;
  right: 0;
  margin-top: 16px;
  margin-right: 24px;
  position: relative; }

.main-menu.bottom .menu-outer-buttons, .menu-position-bottom .menu-outer-buttons {
  bottom: 100%;
  left: 0;
  top: auto;
  right: auto;
  margin-bottom: 16px;
  margin-left: 24px; }

/* Vertical mode */
.main-menu.left, .main-menu.right {
  width: 280px; }
  .main-menu.left .section-list .section, .main-menu.right .section-list .section {
    height: 110px;
    margin: 0.5em 0; }
  .main-menu.left .section-list .section:first-child, .main-menu.right .section-list .section:first-child {
    margin-top: 0; }
  .main-menu.left .section-list .section:last-child, .main-menu.right .section-list .section:last-child {
    margin-bottom: 0; }

.main-menu.left .menu-outer-buttons, .menu-position-left .menu-outer-buttons {
  top: auto;
  left: 100%;
  right: auto;
  bottom: 10%;
  margin-top: 16px;
  margin-left: 24px; }

.main-menu.right .menu-outer-buttons, .menu-position-right .menu-outer-buttons {
  top: auto;
  right: 100%;
  left: auto;
  bottom: 10%;
  margin-top: 16px;
  margin-right: 24px; }

ion-drawer-vertical-wrapper.main-menu {
  height: 100%;
  display: block; }
  ion-drawer-vertical-wrapper.main-menu ion-drawer-vertical-content ion-content.has-footer {
    bottom: 0; }
