.updaterView {
  background-size: cover; }
  .updaterView > .scroll-content > .scroll {
    position: relative;
    width: 100%;
    height: 100%; }
  .updaterView h1 {
    text-align: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    margin-bottom: 0.5em;
    font-size: 4.8em; }
  .updaterView .update-block {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .updaterView .update-status {
    text-align: center; }
  .updaterView .form-errors {
    color: red;
    text-transform: uppercase;
    margin-top: 1em; }
