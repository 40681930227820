ion-drawer-vertical-wrapper.profil {
  box-shadow: none; }

ion-drawer-vertical-wrapper.profil.disable-user-behavior.right.opened {
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  pointer-events: none;
  transition: all ease-in-out 350ms; }

ion-drawer-vertical-wrapper.profil.disable-user-behavior.right {
  background: transparent;
  transition: all ease-in-out 350ms; }

.devis-modal {
  width: 90vw;
  background: white;
  height: 100%;
  margin-left: 10vw;
  pointer-events: all; }
  .devis-modal .modal-inner {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative; }
    .devis-modal .modal-inner .left {
      width: 50%;
      padding: 2% 2% 2% 5%;
      border-right: 2px solid #fa9504;
      margin-top: 3%;
      margin-bottom: 3%; }
      .devis-modal .modal-inner .left .top .coord {
        display: flex;
        flex-direction: row;
        margin-bottom: 35px; }
        .devis-modal .modal-inner .left .top .coord .photo .round {
          width: 140px;
          height: 140px;
          background-position: center center;
          background-size: cover;
          border-radius: 100%;
          background-color: #fa9504;
          margin-right: 35px; }
        .devis-modal .modal-inner .left .top .coord .detail {
          font-family: Ubuntu;
          color: #717171;
          font-size: 1.1em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: 400; }
          .devis-modal .modal-inner .left .top .coord .detail a {
            color: #717171;
            text-decoration: none; }
          .devis-modal .modal-inner .left .top .coord .detail .name-lastname {
            font-size: 1.5em;
            text-transform: uppercase; }
          .devis-modal .modal-inner .left .top .coord .detail .type {
            font-style: italic;
            margin-bottom: 10px; }
      .devis-modal .modal-inner .left .bottom {
        font-family: Ubuntu;
        color: #717171; }
        .devis-modal .modal-inner .left .bottom .header {
          font-weight: bold;
          font-size: 2.5em;
          line-height: 1em;
          text-transform: uppercase;
          margin-bottom: 15px; }
        .devis-modal .modal-inner .left .bottom .body {
          font-size: 1.2em;
          line-height: 1.4em; }
    .devis-modal .modal-inner .right {
      position: relative;
      width: 50%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column; }
      .devis-modal .modal-inner .right h4 {
        text-transform: uppercase;
        text-align: center;
        color: #717171;
        font-weight: bold;
        font-size: 2.6em;
        margin-top: 30px;
        padding-bottom: 25px;
        background-image: url(../img/motif.png);
        background-repeat: no-repeat;
        background-position: 50% 80%;
        display: table;
        margin-left: auto;
        margin-right: auto;
        letter-spacing: 2px; }
      .devis-modal .modal-inner .right .files {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; }
        .devis-modal .modal-inner .right .files .user-file-element {
          display: flex;
          flex-direction: column;
          width: 33.333%;
          padding: 0 4%;
          border-bottom: none; }
          .devis-modal .modal-inner .right .files .user-file-element .doc-filename {
            font-size: 14px;
            line-height: normal;
            color: #666666;
            text-transform: uppercase;
            word-break: break-all;
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical; }
      .devis-modal .modal-inner .right .scroll-content {
        bottom: 74px; }
      .devis-modal .modal-inner .right ion-footer-bar {
        box-shadow: none;
        border-top-width: 0;
        background-image: none;
        height: 74px;
        background-color: transparent; }
      .devis-modal .modal-inner .right .button.round.close {
        width: 80%;
        max-width: 80%;
        margin-left: 10%;
        margin-top: 15px;
        margin-bottom: 15px; }
        .devis-modal .modal-inner .right .button.round.close.activated {
          background-color: #58585a;
          color: white; }
      .devis-modal .modal-inner .right .right-content {
        flex: 1; }
        .devis-modal .modal-inner .right .right-content .scroll-content {
          margin-bottom: 78px; }
  .devis-modal .scroll-content > .scroll {
    padding: 1em;
    /* background: #fff; */ }
  .devis-modal h2 {
    font-size: 3.4em;
    font-weight: bold;
    font-style: normal;
    font-family: 'Ubuntu', Arial, sans-serif;
    color: #565658;
    line-height: 0.9em;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
  .devis-modal .button {
    box-shadow: none;
    background-color: white;
    border: 2px solid #58585a;
    color: #58585a;
    height: 35px;
    padding: 0 1rem; }
  .devis-modal .button.btn-download {
    background-color: #717171;
    color: #fff;
    border: none;
    font-size: 1.6em;
    font-weight: bold; }
  .devis-modal .button.icon-download {
    background-image: url(../img/documents-icone.png);
    background-repeat: no-repeat;
    background-size: auto 75%;
    background-position: center;
    height: 110px; }
    .devis-modal .button.icon-download div.lenght-3 {
      position: absolute;
      transform: translate(-77%, -35%);
      top: 50%;
      left: 50%; }
    .devis-modal .button.icon-download div.lenght-4 {
      position: absolute;
      top: 43px;
      left: 18%;
      font-size: .8em; }
  .devis-modal .button.round {
    border-radius: 10px; }
  .devis-modal .contact {
    border-top: 1px dotted black;
    border-bottom: 1px dotted black;
    display: flex;
    padding: 1.4em 1.2em; }
    .devis-modal .contact .photo {
      width: 60px;
      height: 60px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-right: 1.2em; }
    .devis-modal .contact .round {
      border-radius: 100%; }
    .devis-modal .contact .infos .title {
      font-weight: bold;
      font-size: 1.2em;
      line-height: 1em; }
    .devis-modal .contact .infos .details {
      margin-top: 0.4em;
      line-height: 1em; }
  .devis-modal .footer {
    display: flex;
    flex-direction: column;
    margin: 0 0.5rem;
    margin-bottom: 10px; }
    .devis-modal .footer .buttons-download {
      flex: 1;
      display: flex;
      width: 100%;
      margin: 1em auto; }
      .devis-modal .footer .buttons-download .button {
        margin: 0 0.1em; }
      .devis-modal .footer .buttons-download .button.btn-download {
        flex: 1;
        height: auto;
        line-height: 0.9em;
        padding: 0.5rem 1rem;
        white-space: pre-wrap; }
      .devis-modal .footer .buttons-download .button.small {
        flex: 0 0 35px; }
    .devis-modal .footer .button.close {
      margin: 0 auto;
      width: 100%;
      text-align: center;
      font-size: 1.1rem;
      font-weight: 800;
      letter-spacing: 2px; }

.devis-menu-buttons {
  width: auto;
  height: auto;
  position: absolute;
  z-index: 10; }

.devis-menu-buttons.menu-position-left {
  top: auto;
  left: 0;
  right: auto;
  bottom: 10%; }

.devis-menu-buttons.menu-position-right {
  top: auto;
  right: 0;
  left: auto;
  bottom: 10%; }

.devis-menu-buttons.menu-position-top {
  top: 0;
  left: auto;
  bottom: auto;
  right: 0; }

.devis-menu-buttons.menu-position-bottom {
  bottom: 0;
  left: 0;
  top: auto;
  right: auto; }
