@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

body {
	font-family: 'Ubuntu', Arial;
	font-weight: 500;
}

h1 {
	font-family: 'ITCAvantGardeStd', Arial;
	font-weight: bold;
	width: auto;
	font-size: 5em;
	text-transform: uppercase;
	padding-bottom: 0.25rem;
	margin: 0;
}
.wave {
	mask-image: url(../img/border-wave-white.png);
	mask-repeat: repeat-x;
	mask-position: left bottom;
	background-repeat: repeat-x;
	background-position: left bottom;
	display: block;
	height: 13px;
}
.white {
	color: #fff;
}
.blue {
	color: #327c95;
}
.black {
	color: #000;
}

.form {
	margin-top: 3em;
}
.form input {
	@include placeholder {
		color: #327c95;
	}
	font-weight: 500;
	font-size: 1.2em;
	color: #327c95;
	border: 2px solid;
	border-color: #327c95;
	border-radius: 25px;
	padding: 0 1em;
	width: 100%;
	min-height: 55px;
	background-color: white;
}
.form input[type="submit"] {
	background-color: transparent;
	padding: 0.8em 1em;
}

on-nav-view[nav-view-transition][nav-view-direction]{
  background-color: transparent; 
 }
[nav-view-transition="ios"] [nav-view] {
  box-shadow: none !important; 
 }
ion-view.isotope-view.horizontal.menu-position-left[nav-view="cached"]{
	display: none;
}
ion-view[nav-view="cached"] { 
	transform: translate3d(0,0,0) !important 
}

.align-left {
	text-align: left;
}
.align-right {
	text-align: right;
}
.align-center {
	text-align: center;
}
.block { 
	display: block;
}

.isotope,
.isotope .isotope-item {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 0.8s;
     -moz-transition-duration: 0.8s;
      -ms-transition-duration: 0.8s;
       -o-transition-duration: 0.8s;
          transition-duration: 0.8s;
}

.isotope {
  -webkit-transition-property: height, width;
     -moz-transition-property: height, width;
      -ms-transition-property: height, width;
       -o-transition-property: height, width;
          transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
     -moz-transition-property:    -moz-transform, opacity;
      -ms-transition-property:     -ms-transform, opacity;
       -o-transition-property:      -o-transform, opacity;
          transition-property:         transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
     -moz-transition-duration: 0s;
      -ms-transition-duration: 0s;
       -o-transition-duration: 0s;
          transition-duration: 0s;
}

.isotope-view.menu-position-left .layout-content > .scroll > .isotope{
	margin-left: 80px;
}
.isotope-view.horizontal {
	/* Needed for horizontal view's height calculation */
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	
	.layout {
		flex: 1;
	}
	/* */
}
.isotope-view {
	padding-top: 1rem;
	margin-bottom: 130px;

	.isotope-container {
		width: 100%;
		height:100%;
	}

	.isotope {
		width: 100%;
		height: 100%;
	}
	
	.scroll-content {
		height: auto;
	}
	.scroll-content > .scroll {
		position: relative;
		width: 100%;
		height: 100%;
	}

	.header {
		padding-left: 2rem;
	}
	h1 {
		color: #58585a;
	}
	.layout {
		position: relative;
		height: auto;
	}
	.layout-content {
	}
	.brick {
		float: left;
		cursor: default;
		overflow: hidden;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		
	}
	.brick.left .infos {
		left: 0;
	}
	.brick.right .infos {
		right: 0;
	}
	.brick.center .infos {
		width: 93%;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	.filters {
		margin-top: 1em;
		margin-left: 1em;
		
		li {
			display: inline-block;
			color: #327c95;
			margin: 0 0.5em;
			margin-bottom: 0.5em;
			padding: 0.25em 1em;
			text-transform: uppercase;
			font-weight: bold;
		}
		li.active {
			box-shadow: 0 0 10px 1px rgba(157, 170, 197, 0.6);
		}
	}
}

/* Image modal */
.image-modal, .video-modal {
	background: rgba(0,0,0,0.7);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	    
	
	.swiper-slide {
		display: flex;
	}
	.pane {
		background: transparent;
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
		-webkit-justify-content: center;
		justify-content: center;
	}
	
	a.button-icon.close-cross {
		margin-top: 3%;
		height: 100px;
		box-shadow: none;
		position: absolute;
		top: 0;
		right: 0;
	}
	.image-modal {
		width: 100% !important;
		height: 100%;
		top: 0 !important;
		left: 0 !important;
	}
	.fullscreen-image {
		-webkit-align-self: center;
		align-self: center;
		max-width: 80%;
		max-height: 80%;
		margin: 0 auto;
		width: 100%;
	    height: 100%;
	    background-repeat: no-repeat;
	    background-size: cover;
	    background-position: center;
	}
	
	video {
		width: 70%;
		margin: 0 auto;
	}
}

/* Fade using ngShow/ngHide */
.modal-fade.ng-hide {
	opacity: 0;
}
.modal-fade.ng-hide-remove,
.modal-fade.ng-hide-add {
	display: block !important;
}
.modal-fade.ng-hide-remove {
	transition: all linear 250ms;
}
.modal-fade.ng-hide-add {
	transition: all linear 250ms;
}

/* Fix modal backdrop for smaller devices */
@media (max-width: 679px) {
	.active .modal-backdrop-bg {
		opacity: .5;
	}
	.modal-backdrop-bg {
		-webkit-transition: opacity 300ms ease-in-out;
		transition: opacity 300ms ease-in-out;
		background-color: #000;
		opacity: 0;
	}
}

.header .filters {
	position: relative;
	height: 50px;
	
	.scroll {
		height: 100%;
	}
	
	ul {
		height: 100%;
		white-space: nowrap;
		
		li {
			display: inline-block;
			position: relative;
		}
	}
}

.user-file-element {
	display: block;
	border-bottom: 1px #DDD dotted;
	margin-bottom: 10px;
	.doc-filename {
		line-height: 55px;
		font-size: 14px; 
		color: #868686;
	}
	.btn-download {
		float: right;
	}

}

.slide-in-right {
  -webkit-transform: translateX(100%);
    transform: translateX(100%); }

.slide-in-right.ng-enter, .slide-in-right > .ng-enter {
  -webkit-transition: all cubic-bezier(0.1, 0.7, 0.1, 1) 400ms;
  transition: all cubic-bezier(0.1, 0.7, 0.1, 1) 400ms; }

.slide-in-right.ng-enter-active, .slide-in-right > .ng-enter-active {
  -webkit-transform: translateX(0);
    transform: translateX(0); }

.slide-in-right.ng-leave, .slide-in-right > .ng-leave {
  -webkit-transition: all ease-in-out 250ms;
  transition: all ease-in-out 250ms; }
.my-custom-popup {

	.popup{
		width: 400px;
	}
	.popup-title{
		text-transform: uppercase;
	}

	.popup-buttons button{
	background-color: #327c95
	}
}

ng-include .header .has-footer{
	bottom: 0px;
}
