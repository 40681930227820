.sliderView {
  /* Text bottom + Menu bottom */
  /* Text top + Menu top */
  /* Text left + Menu left */
  /* Text right + Menu right */ }
  .sliderView > .scroll-content > .scroll {
    position: relative;
    width: 100%;
    height: 100%; }
  .sliderView .video-js {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%; }
  .sliderView .vjs-video-wrap {
    width: 100%;
    height: 100%; }
  .sliderView vg-media {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
    .sliderView vg-media video {
      width: 100%;
      max-height: 100%; }
  .sliderView videogular vg-controls, .sliderView [videogular] vg-controls {
    bottom: auto;
    top: 5px; }
    .sliderView videogular vg-controls .controls-container, .sliderView [videogular] vg-controls .controls-container {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      background-color: transparent; }
      .sliderView videogular vg-controls .controls-container vg-play-pause-button, .sliderView [videogular] vg-controls .controls-container vg-play-pause-button {
        display: block; }
      .sliderView videogular vg-controls .controls-container vg-volume, .sliderView videogular vg-controls .controls-container [videogular] vg-volume, .sliderView [videogular] vg-controls .controls-container vg-volume, .sliderView [videogular] vg-controls .controls-container [videogular] vg-volume {
        display: block; }
  .sliderView .custom-swiper-pagination,
  .sliderView .swiper-container .swiper-pagination {
    bottom: 0px;
    left: 35px;
    right: 35px;
    width: calc(100% - 70px);
    display: flex !important;
    justify-content: center; }
  .sliderView .custom-swiper-pagination .swiper-pagination-bullet,
  .sliderView .swiper-container .swiper-pagination .swiper-pagination-bullet {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0; }
  .sliderView .swiper-slide {
    background-size: cover;
    overflow: hidden;
    background-color: #232323; }
  .sliderView .swiper-pagination-bullet {
    background: transparent; }
  .sliderView span.swiper-pagination-bullet:before {
    content: '';
    width: 25px;
    height: 25px;
    position: absolute;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    border: 3px solid white;
    left: 8px;
    right: 0;
    top: 8px;
    box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.5); }
  .sliderView .video-relative {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%; }
  .sliderView video.videoPlayer {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
    z-index: -1;
    transform: translateX(-50%) translateY(-50%); }
  .sliderView span.swiper-pagination-bullet:after {
    content: '';
    width: 25px;
    height: 25px;
    position: absolute;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    left: 8px;
    right: 0;
    top: 8px;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.5); }
  .sliderView span.swiper-pagination-bullet-active:before {
    box-shadow: none; }
  .sliderView span.swiper-pagination-bullet-active:before {
    background-color: white; }
  .sliderView span.swiper-pagination-bullet {
    opacity: 1 !important; }
  .sliderView .block-infos {
    position: absolute;
    max-width: 90%;
    font-family: 'Ubuntu', Arial;
    display: flex;
    flex-direction: row;
    z-index: 10; }
    .sliderView .block-infos.bottom-right {
      margin: 0px 25px 50px 0px !important; }
    .sliderView .block-infos .left .logo {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-color: transparent;
      width: 100px;
      height: 100px;
      margin: 1em;
      margin-top: 0; }
    .sliderView .block-infos .left .toggle-text {
      background-image: url(../img/cross.png);
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: contain;
      background-position: center;
      width: 30px;
      height: 30px;
      margin-left: auto;
      margin-right: 1em; }
    .sliderView .block-infos .left .toggle-text.active {
      background-image: url(../img/arrow-down.png); }
    .sliderView .block-infos .right {
      margin-left: 1em; }
      .sliderView .block-infos .right .title {
        color: #fff;
        font-size: 4.5em;
        line-height: 1em;
        padding: 0.25em 0.25em;
        padding-bottom: 0.1em;
        margin-bottom: 0.45em;
        text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.75); }
      .sliderView .block-infos .right .wave {
        mask-size: auto 10px;
        background-color: #fff; }
      .sliderView .block-infos .right .texts {
        margin-top: 1.25rem;
        padding-right: 3.25rem; }
      .sliderView .block-infos .right .text {
        padding: 0.5rem 1rem;
        white-space: pre; }
      .sliderView .block-infos .right .icon-buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center; }
        .sliderView .block-infos .right .icon-buttons .icon-button {
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: none;
          width: 40px;
          height: 40px;
          margin: 0px 20px 0px 0px !important;
          width: 55px !important;
          height: 55px !important;
          padding: 15px !important; }
          .sliderView .block-infos .right .icon-buttons .icon-button .icon {
            background-color: transparent;
            background-repeat: no-repeat;
            background-size: 65% auto;
            background-position: center;
            mask-repeat: no-repeat;
            mask-size: 65% auto;
            mask-position: center;
            display: block;
            width: 100%;
            height: 100%; }
        .sliderView .block-infos .right .icon-buttons .icon-button.active {
          background-color: white;
          border-radius: 100%;
          box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.5);
          margin: 0px 20px 0px 0px !important;
          width: 55px !important;
          height: 55px !important; }
  .sliderView .block-infos {
    -webkit-transform: translateZ(0); }
  .sliderView .block-infos.top-right {
    top: 20px;
    right: 0; }
  .sliderView .block-infos.top-left {
    top: 0;
    left: 0; }
  .sliderView .block-infos.bottom-left {
    bottom: 0;
    left: 0; }
  .sliderView .block-infos.bottom-right {
    bottom: 0;
    right: 0; }
  .sliderView .block-infos.has-text.menu-position-bottom.bottom-left,
  .sliderView .block-infos.has-text.menu-position-bottom.bottom-right {
    bottom: 35px; }
  .sliderView .block-infos.has-text.menu-position-top.top-left,
  .sliderView .block-infos.has-text.menu-position-top.top-right {
    top: 35px; }
  .sliderView .block-infos.has-text.menu-position-left.top-left,
  .sliderView .block-infos.has-text.menu-position-left.bottom-left {
    left: 35px; }
  .sliderView .block-infos.has-text.menu-position-right.top-right,
  .sliderView .block-infos.has-text.menu-position-right.bottom-right {
    right: 35px; }
