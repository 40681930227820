.contactsView {
  display: flex;
  flex-direction: column; }
  .contactsView .brick .image {
    width: 100%;
    height: 80%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px; }
  .contactsView .brick .infos {
    background: white;
    width: 75%;
    padding: 0.2em;
    border-top-right-radius: 10px;
    position: absolute;
    bottom: 2%;
    font-family: 'Ubuntu Regular', Arial; }
    .contactsView .brick .infos .title {
      font-weight: bold;
      font-size: 1.5em;
      line-height: 1.5em; }
