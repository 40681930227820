.documentsView {
  display: flex;
  flex-direction: column; }
  .documentsView .has-footer {
    bottom: 0; }
  .documentsView .layout {
    flex: 1 0 auto !important; }
  .documentsView .brick .image {
    width: 100%;
    height: 80%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 10px;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    background-color: black; }
  .documentsView .brick .infos {
    width: 75%;
    position: absolute;
    bottom: 0; }
  .documentsView .scroll-content .user-file-element {
    display: flex;
    flex-direction: column;
    width: 22vw;
    padding: 0 4%;
    border-bottom: none;
    float: left;
    padding-left: 40px !important;
    position: absolute; }
    .documentsView .scroll-content .user-file-element .doc-filename {
      line-height: 16px;
      overflow: hidden;
      height: 47px;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding-top: 5px; }
    .documentsView .scroll-content .user-file-element .btn-download {
      background-image: url(../img/documents-icone.png);
      background-repeat: no-repeat;
      -o-background-size: auto 80%;
      background-size: auto 80%;
      background-position: center;
      background-color: #717171;
      height: 100%;
      border-radius: 10px;
      color: #fff;
      border: none;
      font-size: 1.6em;
      font-weight: bold; }
      .documentsView .scroll-content .user-file-element .btn-download .lenght-3 {
        font-size: 30px;
        position: absolute;
        transform: translate(-78%, -35%);
        top: 50%;
        left: 50%; }
  .documentsView pdf-viewer {
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .documentsView pdf-viewer canvas {
      max-height: 90vh; }
  .documentsView .prev {
    position: absolute;
    left: 12vw;
    top: 0;
    transform: translateY(50vh);
    background-image: url("../img/arrow-left.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 57px; }
  .documentsView .next {
    position: absolute;
    right: 12vw;
    top: 0;
    transform: translateY(50vh);
    background-image: url("../img/arrow-right.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 57px; }
  .documentsView .page-count {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 2em;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0; }
  .documentsView ion-spinner.spinner > * {
    width: 38px;
    height: 38px;
    stroke: #fff;
    fill: #222; }
  .documentsView ion-spinner.spinner {
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%; }
  .documentsView .button.close-cross {
    z-index: 100; }
