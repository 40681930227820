/* ITCAvantGardeStd */
@font-face {
  font-family: 'ITCAvantGardeStd';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/itcavantgardestd/ITCAvantGardeStd-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* Ubuntu */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/ubuntu/Ubuntu-R.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/ubuntu/Ubuntu-M.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/ubuntu/Ubuntu-B.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: lighter;
  src: url('../fonts/ubuntu/Ubuntu-L.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* Roboto */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto/roboto-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/roboto/roboto-bold.ttf') format('truetype'); /* Safari, Android, iOS */
}