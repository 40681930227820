.loginView {
  background-size: cover; }
  .loginView > .scroll-content > .scroll {
    position: relative;
    width: 100%;
    height: 100%; }
  .loginView h1 {
    text-align: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 4.8em; }
  .loginView .signin-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 5%; }
    .loginView .signin-form .inner {
      width: auto; }
    .loginView .signin-form input {
      margin-bottom: 0.2em;
      margin-top: 0.6em; }
    .loginView .signin-form input[type="submit"] {
      margin-top: 2em; }
    .loginView .signin-form a {
      font-family: 'Ubuntu', Arial;
      font-weight: bold;
      color: #327c95;
      text-transform: uppercase;
      font-size: 0.75em;
      display: block;
      padding-right: 2em; }
    .loginView .signin-form a.back-to-login {
      margin-top: 0.8em; }
  .loginView .form-errors {
    color: red;
    text-transform: uppercase; }
  .loginView .copyright {
    position: absolute;
    bottom: 16px;
    left: 16px;
    color: #fff;
    font-weight: bold;
    font-size: 1.2em;
    text-decoration: underline; }
  .loginView .version {
    position: absolute;
    bottom: 16px;
    right: 16px;
    color: #fff;
    font-weight: bold;
    font-size: 1.2em; }
