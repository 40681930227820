.placesView {
  display: flex;
  flex-direction: column; }
  .placesView .brick .image {
    width: 100%;
    height: 80%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
  .placesView .brick .infos {
    background: white;
    width: 75%;
    padding: 0.5em;
    position: absolute;
    bottom: 30px;
    font-family: 'Ubuntu Regular', Arial; }
    .placesView .brick .infos .title {
      font-weight: bold; }
