ion-drawer-vertical-wrapper {
	display: block;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	right: 0;
	height: 100%;
	box-shadow: 0 0 10px #333;
	z-index: 8; /* the ion-header-bar is 9, so we need to stay below that */
	background: #fff;
}
ion-drawer-vertical-wrapper.bottom,
ion-drawer-vertical-wrapper.top {
	height: 200px;
}
ion-drawer-vertical-wrapper.left {
	left: initial;
	right: initial;
}

ion-drawer-vertical-handle {
	display: block;
	position: absolute;
}

/* TOP */
ion-drawer-vertical-wrapper.top/*.closed*/ {
	top: 0;
	bottom: auto;
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0);
}

ion-drawer-vertical-wrapper.top.has-footer {
	bottom: 44px;
}

ion-drawer-vertical-wrapper.top.has-subfooter {
	bottom: 88px;
}

ion-drawer-vertical-wrapper.top.has-tabs, ion-drawer-vertical-wrapper.top.bar-footer.has-tabs {
	bottom: 49px;
}

ion-drawer-vertical-wrapper.top.has-tabs.pane, .bar-footer.has-tabs.pane {
	bottom: 49px;
	height: auto;
}

ion-drawer-vertical-wrapper.top.has-footer.has-tabs {
	bottom: 93px;
}

ion-drawer-vertical-wrapper.top ion-drawer-vertical-handle {
	top: 100%;
	left: 0;
	bottom: auto;
	right: auto;
	
	width: 100%;
	height: 35px;
}
/* /TOP */

/* BOTTOM */
ion-drawer-vertical-wrapper.bottom/*.closed*/ {
	top: auto;
	bottom: 0;
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}

ion-drawer-vertical-wrapper.bottom.has-footer {
	bottom: 44px;
}

ion-drawer-vertical-wrapper.bottom.has-subfooter {
	bottom: 88px;
}

ion-drawer-vertical-wrapper.bottom.has-tabs, ion-drawer-vertical-wrapper.bottom.bar-footer.has-tabs {
	bottom: 49px;
}

ion-drawer-vertical-wrapper.bottom.has-tabs.pane, .bar-footer.has-tabs.pane {
	bottom: 49px;
	height: auto;
}

ion-drawer-vertical-wrapper.bottom.has-footer.has-tabs {
	bottom: 93px;
}

ion-drawer-vertical-wrapper.bottom ion-drawer-vertical-handle {
	bottom: 100%;
	left: 0;
	top: auto;
	right: auto;
	
	width: 100%;
	height: 35px;
}
/* /BOTTOM */

/* LEFT */
ion-drawer-vertical-wrapper.left/*.closed*/ {
	left: 0;
	right: auto;
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
}

ion-drawer-vertical-wrapper.left.has-header {
	top: 44px;
}

ion-drawer-vertical-wrapper.left.has-subheader {
	top: 88px;
}

ion-drawer-vertical-wrapper.left.has-tabs-top {
	top: 93px;
}

ion-drawer-vertical-wrapper.left.has-header.has-subheader.has-tabs-top {
	top: 137px;
}

ion-drawer-vertical-wrapper.left ion-drawer-vertical-handle {
	top: 0;
	left: 100%;
	right: auto;
	bottom: auto;
	
	width: 35px;
	height: 100%;
}
/* /LEFT */

/* RIGHT */
ion-drawer-vertical-wrapper.right/*.closed*/ {
	left: auto;
	right: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
}

ion-drawer-vertical-wrapper.right.has-header {
	top: 44px;
}

ion-drawer-vertical-wrapper.right.has-subheader {
	top: 88px;
}

ion-drawer-vertical-wrapper.right.has-tabs-top {
	top: 93px;
}

ion-drawer-vertical-wrapper.right.has-header.has-subheader.has-tabs-top {
	top: 137px;
}

ion-drawer-vertical-wrapper.right ion-drawer-vertical-handle {
	top: 0;
	right: 100%;
	left: auto;
	bottom: auto;
	
	width: 35px;
	height: 100%;
}
/* /RIGHT */

ion-drawer-vertical-wrapper.opened {
	transform: translate3d(0, 0, 0);
}

ion-drawer-vertical-wrapper.animate {
	-webkit-transition: -webkit-transform 0.4s ease-in-out;
	transition: transform 0.4s ease-in-out;
}